import React, { useEffect, useState } from "react";
import css from "./style.module.css"
import check from "../../../Assets/check.png"
import PRODUCT_CARD from "../../CARD/PRODUCT_CARD";
import { useCategory } from "../../../Context/categoryContext";
import { useProduct } from "../../../Context/productContext";
import axios from "axios";
import { Link } from "react-router-dom";

const FILES_CONTAINER = (props) => {
    const [active, setActive] = useState("Тоглоом")
    const {categories} = useCategory()

    const [products, setProducts] = useState([])

    useEffect(() => {
        const fetchProduct = async () => {
            try 
            {
                const response = await axios.get(`https://omn1group.com/api/v1/product?page=${1}&itemsPerPage=${3}`)
                if(response.status === 200)
                {
                    setProducts(response.data.data)
                }
            }
            catch(err)
            {
                console.log(err)
            }
        }
        fetchProduct()
    }, [])

    const [qr, setQr] = useState(null)

    return (
        <div className={css.containerFluid}>
                <div className={css.left}>
                    <p className={css.leftTitle}>Файлын ангилал</p>
                    <ul className={css.leftList}>
                    <Link to={`/files/all`} style={{textDecoration:"none", color:"white"}}>
                    <li className={css.leftListItem} onClick={()=>{setActive("Бүгд")}}> 
                            <div className={css.checkBox}>
                                {active === "Бүгд" && <img src={check} className={css.check}/>}
                            </div>
                            <label className={css.listName}>
                                Бүгд
                            </label>
                    </li>
                    </Link>
                       
                    {
                        categories && categories.length > 0 
                        ? 
                        categories.map((e, i) => (
                            <Link to={`/files/${e.name}`} style={{textDecoration:"none", color:"white"}}>
                            <li className={css.leftListItem} onClick={()=>{setActive(e.name)}} key={i}> 
                                    <div className={css.checkBox}>
                                    {active === e.name && <img src={check} className={css.check}/>}
                                    </div>
                                <label className={css.listName}>
                                    {e.name}
                                </label>
                            </li>
                            </Link>
                            ))
                            : 
                            "Төрөл байхгүй байна"
                        }
                    </ul>
             
                </div>
                <div className={css.right}>
                    <div className={css.rightContainer}>
                        <p className={css.leftTitle} style={{color:"white"}}>{active}</p>
                        <div className={css.hr}></div>
                        {
                            products && products.length > 0
                            ?
                            products.map((e, i) => (
                                <div style={{color:"white"}}>
                                </div>
                            ))
                            : 
                            null
                        }
                        <button className={css.moreBtn}>ЦААШ ҮЗЭХ</button>
                        
                        
                    </div>
                </div>
        </div>
    )
}   

export default FILES_CONTAINER