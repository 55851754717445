import React, { useEffect, useState, useMemo } from "react";
import css from "./style.module.css"
import NON_TOUCHABLE_STAR from "../../STAR/NON_TOUCHABLE";
import { useAuth } from "../../../Context/authContext";
import { Link , useNavigate} from "react-router-dom";
import { useMessage } from "../../../Context/messageContext";
import axios from "axios";


const generateRandomId = () => {
    return 'checkout-' + Math.random().toString(36).substr(2, 9); 
};

const RIGHT_PRODUCT_DETAIL = (props) => {
    const [isGet, setIsGet] = useState(false);
    const { userProduct, user } = useAuth();
    const navigate = useNavigate()
    const {setModalMessage} = useMessage()
    const checkIsBuy = useMemo(() => {
        if (userProduct && props.product) {
            const find = userProduct.find((item) => item.product === props.product.id);
            return find ? true : false;
        }
        return false;
    }, [userProduct, props.product]);
    useEffect(() => {
      
        setIsGet(checkIsBuy);
    }, [checkIsBuy]);


    const [checkout, setCheckout] = useState(generateRandomId())
    const [checkoutId, setCheckoutId] = useState(null)

    const createCheckout = async () => {
        try {
            const response = await axios.post("https://omn1group.com/api/v1/checkout", {
                checkout_code: checkout,
                type: "product",
                user: user.id,
                product: props.product && props.product.id ? props.product.id : null
            }, {
                withCredentials: true 
            });

            if(response.status === 200)
            {
                const checkout = response.data.data[0]
                setTimeout(() => {
                    navigate(`/files/${props.product && props.product.category_name ? props.product.category_name : null}/${props.product && props.product.id ?  props.product.id : null}/${checkout.id}`)
                }, 1500)
            }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <div className={css.containerFluid}>
            <p className={css.title}>{props.product && props.product.product_name ? props.product.product_name : "Гарчиг байхгүй байна."} </p>
            <p className={css.publisher}>Оруулсан: <label style={{textDecoration:"underline", fontWeight:"400"}}>{props.product && props.product.username ? props.product.username : "өгөгдөл байхгүй"}</label></p>
            <NON_TOUCHABLE_STAR star={props.product && props.product.rating ? props.product.rating : "0" } review ={props.product && props.product.review_count ? props.product.review_count : "0"}/>
            <p className={css.shortDesc}>{props.product && props.product.short_desc ? props.product.short_desc : "Богино тайлбар байхгүй"}</p>

            <div className={css.containerSub}>

            </div>

            {
                isGet 
                ? 
                <button className={css.button} onClick={()=>{setModalMessage("Та худалдаж авсан байна")}}>
                {props.product && props.product.price ? props.product.price + "₮" : "Үнэ байхгүй"}
                </button>
                : 
                <Link style={{width:"100%"}} onClick={createCheckout}>
                <button className={css.button}>
                {props.product && props.product.price ? props.product.price + "₮" : "Үнэ байхгүй"}
                </button>
                </Link>
            }
            <button className={css.buttonAddFavourite}>
                Хадгалах
            </button>
        </div>
    )

}
   

export default RIGHT_PRODUCT_DETAIL