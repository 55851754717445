import React, { useEffect, useState, useCallback  } from "react";
import css from "./style.module.css"
import { useProduct } from "../../Context/productContext";
import { useCategory } from "../../Context/categoryContext";
import { Link, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import PRODUCT_CARD from "../../Components/CARD/PRODUCT_CARD";


const CATEGORY_PAGE = (props) => {

    const {categories } = useCategory()
    const {category} = useParams()


    const [product, setProduct] = useState([])
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(0)
    const [itemPage, setItemPage] = useState(5)
    const [currentPage, setCurrentPage] = useState(1)


    const generatePageNumbers = () => {
        const range = 5; 
        let start = Math.max(1, page - Math.floor(range / 2)); 
        let end = Math.min(maxPage, start + range - 1); 
    
        
        if (end - start < range - 1) {
          start = Math.max(1, end - range + 1);
        }
    
        const pageNumbers = [];
        for (let i = start; i <= end; i++) {
          pageNumbers.push(i);
        }
        return pageNumbers;
    };

    useEffect(() => {
        const getProducts = async () => {
            try 
             {  
                if(category === "all")
                {
                    const response = await axios.get(`https://omn1group.com/api/v1/product?page=${page}&itemsPerPage=${5}`)
                    if(response) 
                    {
                        setProduct(response.data.data)
                        setMaxPage(response.data.maxPage)
                        setCurrentPage(response.data.currentPage)
                    }
                    return;
                }
             
                const find = categories.find((item) => item.name === category)
                const response =   await axios.get(`https://omn1group.com/api/v1/product/${find.id}?page=${page}&itemsPerPage=${5}`)
                setProduct(response.data.data)
                setMaxPage(response.data.maxPage)
                setCurrentPage(response.data.currentPage)


               

            }
            catch(err)
            {
                if(err)
                {
                    setProduct([])
                    setMaxPage(1)
                    setCurrentPage(1)
                }
            }
        } 
        getProducts()
    }, [page, itemPage, category, categories])


    useEffect(() => {
        const container = document.querySelector(`.${css.containerFluid}`);
        if (container) {
          container.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, [page, category]);

    const pageNumbers = generatePageNumbers()
    const handlePage = () => {
        try 
        {
            setPage(1)
        }
        catch(err)
        {
            console.log(err)
        }
    }
    

    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.left}>
                    <label className={css.title}>Төрлүүд</label>
                    <div className={css.hr}>

                    </div>
                    
                    <ul className={css.list}>
                        <Link to={`/files/all`} className={css.link} onClick={handlePage}> 
                            <li className={css.listItem}>
                                <div className={css.active}>
                                    {category === "all" && <img src={require("../../Assets/activeCheck.png")} className={css.activeCheck}/>}
                                </div>
                                <p className={css.name}>Бүх</p>
                            </li>
                        </Link>
                        {
                            categories && categories.length > 0 

                            ? 

                            categories.map((e, i) => (
                            <Link to={`/files/${e.name}`} className={css.link} onClick={handlePage}>
                             <li className={css.listItem} key={i}>
                                <div className={css.active}>
                                    {category === e.name && <img src={require("../../Assets/activeCheck.png")} className={css.activeCheck}/>}
                                </div>
                                <p className={css.name}>{e.name}</p>
                            </li>
                            </Link>
                            ) )

                            : 

                            "Төрөл байхгүй байна"
                        }
                    </ul>

                </div>
              
                    <div className={css.right}>
                    <div className={css.rightContainer}>
                    
                    {
                        product && product.length > 0 

                        ?

                        product.map((e, i) => (
                            <PRODUCT_CARD id={e.id} category={e.category_name} title={e.product_name} shortDesc={e.short_desc} desc={e.desc} price={e.price} image={e.img_url} date={e.create_date} rating={e.rating} user={e.username} profileUser={e.profile_img} review={e.review_count} />
                        ))
                    
                        :

                        <div>Файл байхгүй байна</div>
                    }
    
                    
                    <div className={css.paginationContainer}>
                                <div className={css.pagesContainer}>
                                    <div className={css.mapPage}>
                                        <img src={require("../../Assets/arrowLeft.png")}/>
                                    </div>
                                    {
                                    pageNumbers && pageNumbers.length > 0 ? (
                                        pageNumbers.map((e, i) => (
                                        <div key={i} className={parseInt(currentPage) === e ? css.mapPageActive : css.mapPage} onClick={()=>{setPage(e)}}>
                                            {e}
                                        </div>
                                        ))
                                    ) : null
                                    }
                                    
                                    <div className={css.mapPage}>
                                        <img src={require("../../Assets/arrowRight.png")}/>
                                    </div>
                                </div>
                    </div>

                    </div>
                </div>  
                
            </div>
        </div>
    )
}

export default CATEGORY_PAGE