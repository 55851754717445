import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState({
        username: "",
        id: "",
        role: "",
    });
    
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [userProduct , setUserProduct] = useState([])

    const [isRefresh, setIsRefresh] = useState(true)

    const login = (details) => {
        setIsAuthenticated(true);
        setUser({
            username: details.username,
            id: details.id, 
            role: details.role,
        });
    };


    const logout = () => {
        setIsAuthenticated(false);
        setUser({
            username: "",
            email: "",
            role: "user",
        });
      
    };

    useEffect(() => {
        const getProductsUser = async () => {
            
            try 
            {
                if(!isAuthenticated)
                {
                    return;
                }
                if(isRefresh)
                {
                    const response = await axios.get(`https://omn1group.com/api/v1/product/user/${user.id}`)
                    setUserProduct(response.data.data)
                    setIsRefresh(false)
                    const subResponse = await axios.get(`https://omn1group.com/api/v1/user/subscription/${user.id}`)
                    console.log(subResponse)
                    setStartDate(subResponse.data.data[0].start_date.split("T")[0])
                    setEndDate(subResponse.data.data[0].end_date.split("T")[0])
                }
                
            }
            catch(err)
            {
                console.log(err)
            }
        }
        getProductsUser()
    }, [isAuthenticated, isRefresh]) 
  
 
    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                login,
                logout,
                userProduct,
                isRefresh, 
                setIsRefresh,
                startDate,
                endDate
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
