import React from "react";
import css from "./style.module.css"

const ALL_CATEGORY =  (props) => {
    return (
        <div className={css.containerFluid}>
    
       <div className={css.container}>
            <div className={css.nav}>
                <p className={css.titleNav}>Бүх файл</p>
                <button className={css.addBtn}>Файл нэмэх</button>
            </div>
            <div className={css.containerBody}>
                <div className={css.containerBodyNav}>
                    <p className={css.containerBodyNavItem}>Ангилал нэр</p>
                    <p className={css.containerBodyNavItem}>Файл</p>
                    <p className={css.containerBodyNavItem}>Зарагдсан</p>
                    <p className={css.containerBodyNavItem}>Үйлдэл</p>
                </div>
                <div className={css.containerBodyListContainer}>
                    <div className={css.mapListItem}>
                        <div className={css.containerImageAndName}>
                            <div className={css.detailContainer}>
                                <p className={css.username}>Бямба</p>
                            </div>
                        </div>
                        <p className={css.price}>32</p>
                        <p className={css.selled}>321</p>
                        <div className={css.uildelContainer}>
                            <div className={css.uildelBlock}>
                                <img src={require("../../../Assets/viewAdmin.png")} style={{width:"14px", height:"16px"}}/>
                            </div>
                            <div className={css.uildelBlock}>
                                <img src={require("../../../Assets/pencil.png")} style={{width:"14px", height:"14px"}}/>
                            </div>
                            <div className={css.uildelBlock}>
                                <img src={require("../../../Assets/trash.png")} style={{width:"14px", height:"14px"}}/>
                            </div>
                        </div>
                    </div>
                    
                   
                </div>
                <div className={css.paginationContainer}>
                    <div className={css.pagination}>
                        <button className={css.btnPage}>Өмнөх</button>
                        <button className={css.pageBtn}>1</button>
                        <button className={css.pageBtn}>2</button>
                        <button className={css.pageBtn}>3</button>
                        <button className={css.btnPage}>Дараах</button>
                    </div>
                </div>
            </div>  
       </div>
    </div>
    )
}

export default ALL_CATEGORY