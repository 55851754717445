import React from "react";
import css from "./style.module.css"

const ADD_USERS =  (props) => {
    return (
        <div className={css.containerFluid}>
           ADD USERS
        </div>
    )
}

export default ADD_USERS