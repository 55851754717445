import React from "react";
import css from "./style.module.css"
import DASHBOARD from "../../ADMIN_CONTAINER/1. DASHBOARD";
import ALL_FILE from "../../ADMIN_CONTAINER/2. ALL_FILES";
import ADD_FILE from "../../ADMIN_CONTAINER/3. ADD_FILES";
import ALL_CATEGORY from "../../ADMIN_CONTAINER/4. ALL_CATEGORY";
import ADD_CATEGORY from "../../ADMIN_CONTAINER/5. ADD_CATEGORY";
import ALL_USERS from "../../ADMIN_CONTAINER/6. ALL_USERS";
import ADD_USERS from "../../ADMIN_CONTAINER/7. ADD_USERS";

const ADMIN_CONTAINER = (props) => {
    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.nav}>
                    <p className={css.navTitle}>ТАВТАЙ МОРИЛ!</p>
                    <div className={css.navRightContainer}>
                        <img src={require("../../../Assets/Admin/notification.png")} className={css.icon}/>
                    </div>
                </div>
                <div className={css.containerSection}>
                    {
                        props.container === "home"
                        ?
                        <DASHBOARD/>
                        : 
                        props.container === "allFiles"
                        ?
                        <ALL_FILE/>
                        :
                        props.container === "addFiles"
                        ?
                        <ADD_FILE/>
                        :
                        
                        props.container === "allCategory"
                        ?
                        <ALL_CATEGORY/>
                        :
                        props.container === "addCategory"
                        ?
                        <ADD_CATEGORY/>
                        :
                        props.container === "allCustomers"
                        ?
                        <ALL_USERS/>
                        :
                        props.container === "addCustomers"
                        ?
                        <ADD_USERS/>
                        :
                        null
    }
                </div>
                
            </div>
        </div>
    )
}

export default ADMIN_CONTAINER