import React, { useEffect, useState } from "react";
import css from "./style.module.css";
import ADMIN_SIDEBAR from "../../Components/ADMIN/1. ADMIN_SIDEBAR";
import ADMIN_CONTAINER from "../../Components/ADMIN/2. ADMIN_CONTAINER";


const ADMIN = () => {
    const [isWrap, setIsWrap] = useState(false)
    const [isSmallSize, setIsSmallSize] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [selectedMenu, setSelectedMenu] = useState("dashboard")
    const [container, setContainer] = useState("home")



    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          if(windowWidth < 1025)
          {
            setIsSmallSize(true)
          }
          else 
          {
            setIsSmallSize(false)
            setIsWrap(false)
          }
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [windowWidth]);
    
    return (
       <div className={css.containerFluid}>
            <ADMIN_SIDEBAR isWrap={isWrap} setIsWrap={setIsWrap} isSmallSize={isSmallSize} setSelectedMenu={setSelectedMenu} selectedMenu={selectedMenu} container={container} setContainer={setContainer}/>
            <ADMIN_CONTAINER setIsWrap={setIsWrap} container={container}/>
       </div>
    )
};

export default ADMIN;
