import React, { useEffect, useState } from "react";
import css from "./style.module.css"
import NON_TOUCHABLE_STAR from "../../STAR/NON_TOUCHABLE";
import axios from "axios";
import { useAuth } from "../../../Context/authContext";
import { useMessage } from "../../../Context/messageContext";

const PRODUCT_COMMENT = (props) => {
    const [comments, setComments] = useState([])
    const [message, setMessage] = useState(null)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(5)
    
    const [currentPage, setCurrentPage] = useState(null)
    const [totalComments, setTotalComments] = useState(null)
    const [totalPage, setTotalPage] = useState(null)

    
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const {user} = useAuth()
    const {setModalMessage} = useMessage()

    const generatePageNumbers = () => {
        const range = 5; 
        let start = Math.max(1, page - Math.floor(range / 2)); 
        let end = Math.min(totalPage, start + range - 1); 
    
        
        if (end - start < range - 1) {
          start = Math.max(1, end - range + 1);
        }
    
        const pageNumbers = [];
        for (let i = start; i <= end; i++) {
          pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const pages = generatePageNumbers()


    // useEffect(() => {
    //     const container = document.querySelector(`.${css.containerFluid}`);
    //     if (container) {
    //       container.scrollIntoView({ behavior: "smooth", block: "start" });
    //     }
    //   }, [page]);

    const handleScrollPageChange = () => {

    }

    useEffect(() => {
        const fetchComments = async () => {
            try 
            {
                if(!props.id)
                {
                    return;
                }
          
                const response = await axios.get(`https://omn1group.com/api/v1/comment/${props && props.id ? props.id : null}?page=${page}&item=${limit}`)
                console.log(response)
                if(response.status === 200)
                {
                    setComments(response.data.data)
                    setCurrentPage(response.data.pagination.currentPage)
                    setTotalComments(response.data.pagination.totalComments)
                    setTotalPage(response.data.pagination.totalPages)
                }
                else 
                {
                    setComments([])
                }
            }   
            catch(err)
            {
                setMessage(err.response.data.message)
            }
        }
        fetchComments()
    }, [props, page, limit])


    const handleCreateComment = async (e) => {
        e.preventDefault(e)
        try 
        {
            const response = await axios.post("https://omn1group.com/api/v1/comment", 
            {product:props && props.id ? props.id : null , user: user.id, title:title, comment:content}
            )
            if(response.status === 200)
            {
                setModalMessage("Сэтгэгдэлийг амжилттай нэмлээ")
                setContent("")
                setTitle("")
                setMessage("")

            }
        }
        catch(err)
        {
            setMessage(err.response.data.message)
        }

    }


    return (
        <div className={css.containerFluid}>
            <p className={css.titleComment}>{totalComments} сэтгэгдэл байна.</p>
            <div className={css.productCommentSection}>
                {
                    comments && comments.length > 0 
                    ?
                    comments.map((e, i) => (
                    <div className={css.commentContainer}>
                    <NON_TOUCHABLE_STAR star={"5"}/>
                    <p className={css.commentTitle}>{e.title ? e.title : "Гарчиг байхгүй байна."}</p>
                    <div className={css.dateTimeUser}>
                        <p className={css.textDateTime}>{e.create_date ? e.create_date.split("T")[0] : "N/A"} | {e.user_name ? e.user_name : "N/A" } </p>
                    </div>
                    <div className={css.commentContent}>
                        <p className={css.commentContentText}>{e.comment ? e.comment : "Сэтгэгдэл байхгүй."}</p>
                    </div>
                </div>
                    ))
                    : 
                    null
                }
                <div className={css.addCommentContainer}>
                    <p className={css.titleAddComment}>Сэтгэгдэл үлдээх</p>
                    <NON_TOUCHABLE_STAR star={"5"}/>
                    <div className={css.containerInput}>
                        <input placeholder="Гарчиг" className={css.titleInput} onChange={(e)=>{setTitle(e.target.value)
                                                                                                setMessage("")
                        }}/>
                        <textarea placeholder="Сэтгэгдэл" className={css.textArea} onChange={(e) => {setContent(e.target.value)
                                                                                                        setMessage("")
                        }}/>
                        <div className={css.commentMessage}>{message}</div>
                        <button className={css.addBtnComment} onClick={handleCreateComment}>Үлдээх</button>
                    </div>
                </div>
               
            </div>
            <div className={css.paginationContainer}>
                <div className={css.pagesContainer}>
                    <button className={css.btnPage} onClick={() => setPage((prev) => Math.max(1, prev - 1))} disabled={page === 1}>{"<"}</button>
                    {
                        pages && pages.length > 0
                        ?
                        pages.map((e, i) => (
                            <button className={page === e ? css.btnPageActive : css.btnPage} onClick={()=>{setPage(e)}}>{e}</button>
                        ))
                        :
                        "Хуудас байхгүй."
                    }
                   
                    <button className={css.btnPage} onClick={() => setPage((prev) => Math.min(totalPage, prev + 1))} disabled={page === totalPage}>{">"}</button>
                </div>
            </div>
        </div>
    )
}

export default PRODUCT_COMMENT