import React, { act, useState } from "react";
import css from "./style.module.css"
import logo from "../../../Assets/logo.png"
import search from "../../../Assets/search.png"
import question from "../../../Assets/question.png"
import folder from "../../../Assets/folder.png"
import user from "../../../Assets/user.png"
import favourite from "../../../Assets/favourite.png"
import burger from "../../../Assets/burger.png"
import { useAuth } from "../../../Context/authContext";
import { Link } from "react-router-dom";
import { useCategory } from "../../../Context/categoryContext";

const TOP_NAV = (props) => {
    const {isAuthenticated} = useAuth()
    const [search, setSearch] = useState("")
    const [isSearch , setIsSearch] = useState(false)

    const [activeSearch, setActiveSearch] = useState("Тоглоом")
    const [isDrop, setIsDrop] = useState(false)
    
    const {categories} = useCategory()

    const [onFocus , setOnFocus ] = useState(false)

    const handleSelectActive = (search) => {
        setIsDrop(false)
        setActiveSearch(search)
        setOnFocus(false)
    }

    const handleDropMenu = () => {
        setIsDrop(prev => !prev)
        setOnFocus(prev => !prev)
    }


    return (
        <div className={css.containerFluid} onClick={() => {setIsSearch(false)}}>
                <div className={css.container}>
                   <div className={css.logoContainer}>
                     <Link to={"/"}><img src={logo} className={css.logo} alt="logo"/></Link>
                   </div> 
                   
                    <div className={onFocus ? css.searchContainerActive : css.searchContainer}>
                        
                        <div className={css.selectOptionSearch} >
                            <button className={css.activeSearch} onClick={handleDropMenu}>{activeSearch} <img src={require("../../../Assets/downArrow.png")} className={css.downArrow}/></button>
                            {
                                isDrop 
                                &&  
                                <div className={css.selectCategory} onMouseEnter={()=>{setIsDrop(true)}} onMouseLeave={()=>{setIsDrop(false)}}>
                                {
                                    categories && categories.length > 0 
                                    ? 
                                    categories.map((e, i) => (
                                    <div className={css.selectItem} key={i} onClick={()=>{handleSelectActive(e.name)}}>
                                        {e.name}
                                    </div>
                                    ))
                                    :
                                    null
                                }
                            </div>
                            }
                        </div>
                        <div className={css.searchIconContainer}>
                        <img src={require("../../../Assets/search.png")} className={css.searchIcon} alt="s"/>
                        </div>
                        <input placeholder="Хайх" className={css.input} onChange={(e)=> {setSearch(e.target.value)}} onFocus={()=>{setOnFocus(true)}} onBlur={()=>{setOnFocus(false)}}/>
                       
                    </div>
                    <div className={css.iconContainer}>
                        <div className={css.menuContainer}>
                            <div className={css.iconC}>
                                <img src={question} className={css.icon}/>
                            </div>
                            <div className={css.iconTxtContainer}>
                                <p className={css.txtIcon}>Тусламж</p>
                            </div>
                        </div>
                        <Link to={"/content"}>
                        <div className={css.menuContainer}>
                            <div className={css.iconC}>
                                <img src={favourite} className={css.icon}/>
                            </div>
                            <div className={css.iconTxtContainer}>
                                <p className={css.txtIcon}>Хадгалсан</p>
                            </div>
                            </div>
                        </Link>
                        {
                            !isAuthenticated
                            ? 
                            <div className={css.menuContainer} onClick={() => {props.setModal(true)}}>
                            <div className={css.iconC}>
                                <img src={user} className={css.icon}/>
                            </div>
                            <div className={css.iconTxtContainer} >
                                <p className={css.txtIcon}>Нэвтрэх</p>
                            </div>
                            </div> 
                            : 
                            <Link to={"/profile"}>
                            <div className={css.menuContainer}>
                            <div className={css.iconC}>
                                <img src={user} className={css.icon}/>
                            </div>
                            <div className={css.iconTxtContainer} >
                                <p className={css.txtIcon}>Профайл</p>
                            </div>
                            </div>
                            </Link>
                        }
                        <Link to={"/favourites"}> 
                            <div className={css.menuContainer}>
                            <div className={css.iconC}>
                                <img src={favourite} className={css.icon}/>
                            </div>
                            <div className={css.iconTxtContainer}>
                                <p className={css.txtIcon}>Мэдэгдэл</p>
                            </div>
                            </div>
                        </Link>
                    </div>


                    <div className={css.rightIconsSmall}>
                        {/** НЭВТРЭХ ICON */}
                       {
                        !isAuthenticated 
                        ?
                        <div className={css.smallSizedSearch} onClick={() => {props.setModal(true)}}>
                            <img src={user} className={css.searchIcon}/>
                        </div> 
                        :
                        <div className={css.smallSizedSearch} >
                            <img src={user} className={css.searchIcon}/>
                        </div> 
                       }
                        <div className={css.burgerMenuContainer} onClick={() => {props.setBurgerMenu(true)}}>
                            <img src={burger} className={css.burgerMenu}/>
                        </div>     
                    </div>
                        
                </div>
        </div>
    )
}

export default TOP_NAV