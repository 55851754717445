import React, { useEffect, useState, useMemo } from "react";
import css from "./style.module.css";
import { useAuth } from "../../../Context/authContext";
import NON_TOUCHABLE_STAR from "../../STAR/NON_TOUCHABLE";
import axios from "axios";
import { useNavigate , Link} from "react-router-dom";
import { useMessage } from "../../../Context/messageContext";
import PRODUCT_COMMENT from "../3.COMMENT";


const generateRandomId = () => {
    return 'checkout-' + Math.random().toString(36).substr(2, 9); 
};


const LEFT_PRODUCT_DETAIL = (props) => {
    const [isLink, setIsLink] = useState(false)
    const [isGet, setIsGet] = useState(false);
    const { userProduct, user } = useAuth();
    const navigate = useNavigate()
    const {setModalMessage} = useMessage()
    const checkIsBuy = useMemo(() => {
        if (userProduct && props.product) {
            const find = userProduct.find((item) => item.product === props.product.id);
            return find ? true : false;
        }
        return false;
    }, [userProduct, props.product]);
    useEffect(() => {
      
        setIsGet(checkIsBuy);
    }, [checkIsBuy]);


    const [checkout, setCheckout] = useState(generateRandomId())
    const [checkoutId, setCheckoutId] = useState(null)
    const createCheckout = async () => {
        try {
            const response = await axios.post("https://omn1group.com/api/v1/checkout", {
                checkout_code: checkout,
                type: "product",
                user: user.id,
                product: props.product && props.product.id ? props.product.id : null
            }, {
                withCredentials: true 
            });

            console.log(response)
            if(response.status === 200)
            {
                const checkout = response.data.data[0]
                setTimeout(() => {
                    navigate(`/files/${props.product && props.product.category_name ? props.product.category_name : null}/${props.product && props.product.id ?  props.product.id : null}/${checkout.id}`)
                }, 1500)
            }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <div className={css.containerFluid}>
            <div className={css.containerImage}>
                <img
                    src={`https://omn1group.com/${props.product && props.product.img_url ? props.product.img_url : ""}`}
                    className={css.image}
                    alt="Product"
                />
            </div>
            <div className={css.productDetail}>
                <p className={css.titleProduct}>{props.product && props.product.product_name ? props.product.product_name : "Гарчиг байхгүй байна."} </p>
                <p className={css.publisher}>Оруулсан: <label style={{textDecoration:"underline", fontWeight:"400"}}>{props.product && props.product.username ? props.product.username : "өгөгдөл байхгүй"}</label></p>
                <NON_TOUCHABLE_STAR star={props.product && props.product.rating ? props.product.rating : "0" } review ={props.product && props.product.review_count ? props.product.review_count : "0"}/>
                <p className={css.shortDesc}>{props.product && props.product.short_desc ? props.product.short_desc : "Богино тайлбар байхгүй"}</p>

            </div>


            <div className={css.containerDescription}>
                <p className={css.descTitle}>Тайлбар:</p>
                <p className={css.desc}>
                    {props.product && props.product.desc ? props.product.desc : "Тайлбар оруулаагүй"}
                </p>
            </div>

            {
                isGet 
                ? 
                <button className={css.button} onClick={()=>{setModalMessage("Та худалдаж авсан байна")}}>
                {props.product && props.product.price ? props.product.price + "₮" : "Үнэ байхгүй"}
                </button>
                : 
                <Link style={{width:"100%"}} onClick={createCheckout}>
                <button className={css.button}>
                {props.product && props.product.price ? props.product.price + "₮" : "Үнэ байхгүй"}
                </button>
                </Link>
            }
             <button className={css.buttonAddFavourite}>
                Хадгалах
            </button>
            <div className={css.containerLink}>
                <button className={css.linkBtn} onClick={()=>{setIsLink(prev => !prev)}}>ЛИНК АВАХ</button>
                {
                    isLink ? 
                    isGet ? (
                        props.product && props.product.link ? (
                            <a className={css.linkDiv}>
                                <p className={css.txtBuy}>ТАТАХ</p>
                            </a>
                        ) : (
                            "Линк хоосон байна"
                        )
                    ) : (
                        <div className={css.linkDiv}>
                            <p className={css.txtBuy}>Худалдаж авна уу</p>
                        </div>
                    ) 
                    : null
                }
            </div>
 
            <PRODUCT_COMMENT id={props.product && props.product.id ? props.product.id : null }/> 
        </div> 
    ); 
};

export default LEFT_PRODUCT_DETAIL;
