import React from "react";
import css from "./style.module.css"

const USER_CONTENT = (props) => {

    return (
        <div className={css.containerFluid}>
            <div className={css.containerNav}>
                <div className={css.statBlock}>
                    <div className={css.detailContainer}>
                         <p className={css.number}>83</p>
                         <p className={css.title}>Таны оруулсан контент</p>
                    </div>
                    <div className={css.iconContainer}>
                        <div className={css.iconBg}>
                            <img src={require("../../../Assets/upload.png")}/>
                        </div>
                    </div>
                </div>
                <div className={css.statBlock}>

                </div>
                <div className={css.statBlock}>

                </div>
            </div>
        </div>
    )
} 

export default USER_CONTENT