import React from "react";
import css from "./style.module.css"

const NON_TOUCHABLE_STAR = (props) => {


    return (
        <div className={css.containerFluid}>
            <img src={props.star >= 1 ? require("../../../Assets/star.png") : require("../../../Assets/starInactive.png")} className={css.star}/>
            <img src={props.star >= 2 ? require("../../../Assets/star.png") : require("../../../Assets/starInactive.png")} className={css.star}/>
            <img src={props.star >= 3 ? require("../../../Assets/star.png") : require("../../../Assets/starInactive.png")} className={css.star}/>
            <img src={props.star >= 4 ? require("../../../Assets/star.png") : require("../../../Assets/starInactive.png")} className={css.star}/>
            <img src={props.star >= 5 ? require("../../../Assets/star.png") : require("../../../Assets/starInactive.png")} className={css.star}/>
            <label className={css.rating}>{props.star && props.star !== null ?  props.star.slice(0,1)  : null}</label>
            <label className={css.reviews}>({props.review ? props.review : "0"}) үзсэн</label>
        </div>
    )
}

export default NON_TOUCHABLE_STAR