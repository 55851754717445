import React, { useState } from "react";
import css from "./style.module.css"
import user from "../../../Assets/user.png"
import pass from "../../../Assets/password.png"
import exit from "../../../Assets/exit.png"
import axios from "axios";
import { useAuth } from "../../../Context/authContext";
import LOADING from "../../LOADING";
import { useMessage } from "../../../Context/messageContext";

axios.defaults.withCredentials = true;

const CONTAINER_LOGIN = (props) => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const {login, updateSubscription} = useAuth()

    const {setModalMessage} = useMessage()


    const handleLogin = async () => {
        try 
        {
            const response = await axios.post("https://omn1group.com/api/v1/auth/v1/login", {username, password}, 
                {
                    withCredentials:true
                })
            if(response)
            {
                setIsLoading(true)
                setTimeout(() => {
                    login({username: response.data.data.username, id: response.data.data.id, role: response.data.data.role})
                    setIsLoading(false)
                        setModalMessage("Амжилттай нэвтэрлээ")
                }, 1000);
            }
        }   
        catch(err)
        {   
            setMessage(err.response.data.message)
        }
    }

    if(isLoading) return <LOADING/>

    return (
        <div className={css.containerFluid}>
  
            <div className={css.exitContainer}>
            <img src={exit} className={css.exit} onClick={()=>{props.setModal(false)}}/>    
            </div>
            <p className={css.title}>НЭВТРЭХ</p>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={user} className={css.icon}/>
                </div>
                <input type="text" placeholder="Нэвтрэх нэр" className={css.input} onChange={(e) => {setUsername(e.target.value)}}/> 
                
            </div>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={pass} className={css.icon}/>
                </div>
                <input type="password" placeholder="Нууц үг" className={css.input} onChange={(e) => {setPassword(e.target.value)}}/> 
                
            </div>
            <p className={message === "Амжилттай нэвтэрлээ." ? css.messageGreen : css.message}>{message}</p>
            <button className={css.buttonRegister} onClick={handleLogin}>Нэвтрэх</button>
            <p className={css.bottomTxt}>Бүртгэл үүсгэх үү? <label className={css.linkLogin} onClick={() => {props.setIsLogin(false)}}>энд</label> дарна уу</p>

        </div>
    )
}

export default CONTAINER_LOGIN