import React, { useEffect, useState } from "react";
import css from "./style.module.css"
import axios from "axios";
import { useAuth } from "../../Context/authContext";
import { useMessage } from "../../Context/messageContext";
import { useNavigate } from "react-router-dom";

const generateRandomId = () => {
    return 'checkout-' + Math.random().toString(36).substr(2, 9); 
};


const SUBSCRIPTION = (props) => {

    const [container, setContainer] = useState("sub")
    const [checkoutId, setCheckoutId] = useState(generateRandomId())
    const [checkout, setCheckout] = useState(null)
    const {user, setIsRefresh} = useAuth()
    const [qr, setQr] = useState(null)
    const [invoiceId, setInvoiceId] = useState(null)
    const [message, setMessage] = useState("")
    const {setModalMessage} = useMessage()
    const navigate = useNavigate()
    const [price, setPrice] = useState(null)
    const [subPlan, setSubPlan] = useState(null)

    const handleClickPlan = (id, price) => {

        setPrice(price)
        setSubPlan(id)
    }


    const handleCheckout = async () => {
        if(!user.id)
        {
            setModalMessage("Та нэвтэрснээр тооцоогоо хийн эрхээ сунгах боломжтой")

        }
        try {
            setContainer("payment")
            const response = await axios.post("https://omn1group.com/api/v1/checkout", {
                checkout_code: checkoutId,
                type: "subscription",
                user: user.id,
                product: null
            }, {
                withCredentials: true 
            });

            if(response)
            {
                if(response.status === 200)
                {
                    setCheckout(response.data.data[0])
                }
            }

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {

        const handleQr = async () => {
            try {
                
                if (!checkout) {
                    return; 
                }
                if(qr)
                {
                    return
                }
    
                const response = await axios.post("https://omn1group.com/api/v1/qpay/invoice", {
                    desc: user.username + " сунгалт", 
                    name: user && user.username ? user.username : null, 
                    amount: price, 
                    checkout_id: checkout.id
                });
    
                console.log(response);
                if (response.status === 200) {
                    setQr(response.data.qr_image);
                    setInvoiceId(response.data.invoice_id);
                }
            } catch (err) {
                console.error(err);
            }
        };
    
        handleQr(); 
    }, [checkout, qr, user]);

    const handleCheckQpay = async () => {
        try 
        {
            const response = await axios.post(`https://omn1group.com/api/v1/qpay/check/${invoiceId}` , {
                userId: user.id, product: null
            })
            if(response.status === 200)
            {
                setMessage(response.data.message)
                setIsRefresh(true)
                setModalMessage("Таны эрхийг амжилттай сунгалаа.")
                setTimeout(() => {
                    setModalMessage("")
                    navigate("/")
                },1000)
                
            }
            
        }
        catch(err)
        {
            setMessage(err.response.data.message)
        }
    }

    return (
        <div className={css.containerFluid} >
            {
                container === "sub"
                ?
                <div className={css.container}>
                <div className={css.containerNav}>
                    <p className={css.navTitle}>Багц аа сонго</p>
                </div>
                <div className={css.subContainer}>
                    <div className={css.subscriptionPlan} onClick={()=>{handleClickPlan(1, 100)}}>
                        <p className={css.title}> <img src={require("../../Assets/package.png")}/> <label style={{marginLeft:"10px", paddingBottom:"2px"}}>Энгийн багц</label></p>
                        <p className={css.desc}>гишүүнчлэл</p>
                        <div className={css.containerPrice}>
                            <p className={css.month}>1 сар хязгааргүй</p>
                            <p className={css.price}>19.999MNT/<label className={css.monthLabel}>сар</label></p>
                        </div>
                        <div className={css.benefits}>
                            <p className={css.benefitTxt}>Онцлог/ Давуу тал</p>
                            <div className={css.benefitContainer}>
                                <img src={require("../../Assets/check-circle-svgrepo-com (1)-svg.png")} className={css.checkImage}/>
                                <p className={css.benefitContainerTxt}>Хязгааргүй байршуулалт</p>
                            </div>
                        

                        </div>
                        <button className={subPlan === 1 ? css.selectButtonActive : css.selectButton}>
                            {subPlan === 1 ? "Сонгосон" : "Сонгох"}
                        </button>
                    </div>
                    <div className={css.subscriptionPlan} onClick={()=>{handleClickPlan(2, 200)}}>
                        <p className={css.title}> <img src={require("../../Assets/package.png")}/> <label style={{marginLeft:"10px", paddingBottom:"2px"}}>Энгийн багц</label></p>
                        <p className={css.desc}>гишүүнчлэл</p>
                        <div className={css.containerPrice}>
                            <p className={css.month}>2 сар хязгааргүй</p>
                            <p className={css.price}>39.999MNT/<label className={css.monthLabel}>сар</label></p>
                        </div>
                        <div className={css.benefits}>
                            <p className={css.benefitTxt}>Онцлог/ Давуу тал</p>
                            <div className={css.benefitContainer}>
                                <img src={require("../../Assets/check-circle-svgrepo-com (1)-svg.png")} className={css.checkImage}/>
                                <p className={css.benefitContainerTxt}>Хязгааргүй байршуулалт</p>
                            </div>
                            <div className={css.benefitContainer}>
                                <img src={require("../../Assets/check-circle-svgrepo-com (1)-svg.png")} className={css.checkImage}/>
                                <p className={css.benefitContainerTxt}>Илүү орлого</p>
                            </div>
                        

                        </div>
                        <button className={subPlan === 2 ? css.selectButtonActive : css.selectButton}>
                            {subPlan === 2 ? "Сонгосон" : "Сонгох"}
                        </button>
                    </div>
                    <div className={css.handlePaymentContainer}>
                        <p className={css.tolbor}>Төлбөр: <label style={{color:"#3eb400"}}>{price ? price : 0}.00 MNT</label></p>
                        {
                            price && user.id 
                            ?
                            <button className={css.btn} onClick={handleCheckout}>
                            Үргэлжлүүлэх
                            </button>
                            : 

                            <label style={{marginTop:"10px", color:"red", fontWeight:"500", letterSpacing:"1px"}}>Нэвтэрнэ үү</label>
                        }
                       
                    </div>

                
                </div>



             
            </div> 

            :

            container === "payment"
            ?
            <div className={css.container}>
            
            <div className={css.subContainer}>
                {subPlan === 1 ?
                <div className={css.subscriptionPlan}>
                <p className={css.title}> <img src={require("../../Assets/package.png")}/> <label style={{marginLeft:"10px", paddingBottom:"2px"}}>Энгийн багц</label></p>
                <p className={css.desc}>гишүүнчлэл</p>
                <div className={css.containerPrice}>
                    <p className={css.month}>1 сар хязгааргүй</p>
                    <p className={css.price}>19.999MNT/<label className={css.monthLabel}>сар</label></p>
                </div>
                <div className={css.benefits}>
                    <p className={css.benefitTxt}>Онцлог/ Давуу тал</p>
                    <div className={css.benefitContainer}>
                        <img src={require("../../Assets/check-circle-svgrepo-com (1)-svg.png")} calssName={css.checkImage}/>
                        <p className={css.benefitContainerTxt}>Хязгааргүй байршуулалт</p>
                    </div>
                

                </div>
                <button className={css.selectButtonActive}>
                    Сонгосон
                </button>
                </div>
                :
                subPlan === 2
                
                ?
                    <div className={css.subscriptionPlan} >
                        <p className={css.title}> <img src={require("../../Assets/package.png")}/> <label style={{marginLeft:"10px", paddingBottom:"2px"}}>Энгийн багц</label></p>
                        <p className={css.desc}>гишүүнчлэл</p>
                        <div className={css.containerPrice}>
                            <p className={css.month}>2 сар хязгааргүй</p>
                            <p className={css.price}>39.999MNT/<label className={css.monthLabel}>сар</label></p>
                        </div>
                        <div className={css.benefits}>
                            <p className={css.benefitTxt}>Онцлог/ Давуу тал</p>
                            <div className={css.benefitContainer}>
                                <img src={require("../../Assets/check-circle-svgrepo-com (1)-svg.png")} className={css.checkImage}/>
                                <p className={css.benefitContainerTxt}>Хязгааргүй байршуулалт</p>
                            </div>
                            <div className={css.benefitContainer}>
                                <img src={require("../../Assets/check-circle-svgrepo-com (1)-svg.png")} className={css.checkImage}/>
                                <p className={css.benefitContainerTxt}>Илүү орлого</p>
                            </div>
                        

                        </div>
                        <button className={css.selectButtonActive}>
                            Сонгосон
                        </button>
                    </div>
                    : null

                }

                <div className={css.containerQpay}>
                    <img  src={`data:image/png;base64,${qr}`} style={{width:"250px", height:"250px"}}/>
                    <button className={css.btn2} onClick={handleCheckQpay}>Төлбөр шалгах</button>
                    <p className={css.error}>{message}</p>
                </div>
            </div>

                

            </div>

            : 

            null
            }
        </div>
    )
}

export default SUBSCRIPTION