import React, { useState } from "react";
import css from "./style.module.css"
import { color } from "chart.js/helpers";

const ADMIN_SIDEBAR = (props) => {


    const setContainerAndSelected = (selected, container) => {
        props.setContainer(container)
        props.setSelectedMenu(selected)
    }

    console.log(props.container)
    return (
        <div className={`${css.containerFluid} ${props.isWrap ? css.active : null}`} 
            onMouseEnter={() => {
            if (!props.isSmallSize) {
              props.setIsWrap(true);
            }
            }}
            onMouseLeave={() => {
            if (!props.isSmallSize) {
              props.setIsWrap(false);
            }
            }}
        >
            <div className={css.nav} style={{paddingLeft:props.isWrap ? "25px" : ""}}>
                <img src={require(props.isWrap ? "../../../Assets/logo.png" : "../../../Assets/logoSmall.png")}/>
            </div>
            <div className={`${css.containerListItem} ${props.isWrap ? css.active : ""}`}>
                <label className={`${css.title} ${props.isWrap ? css.active : ""}`}>Ерөнхий</label>

                {/* DASHBOARD */}
                <div className={css.menuContainer} onClick={()=>{setContainerAndSelected("dashboard", "home")}}>
                    <div className={css.selectMenuContainer}>
                        <div className={`${css.activeEffect} ${props.selectedMenu === "dashboard" ? css.active : ""}`}></div>

                        <img src={require(props.selectedMenu === "dashboard" ? "../../../Assets/Admin/dashboard.png" : "../../../Assets/Admin/dashboardUnactive.png")} className={props.selectedMenu === "dashboard" ? css.iconActive : css.icon}/>
                        {props.isWrap ? <label className={css.nameMenu} style={{color:props.selectedMenu === "dashboard" ? "white" : ""}}>Хянах</label> : null}
                    </div>
                </div>

                {/** FILES */}
                <div style={{width:"100%"}}>
                    <div className={css.menuContainer} onClick={()=>{setContainerAndSelected("files", "allFiles")}}>
                        <div className={css.selectMenuContainer}>
                            <div className={`${css.activeEffect} ${props.selectedMenu === "files" ? css.active : ""}`}></div>
                            <img src={require(props.selectedMenu === "files" ? "../../../Assets/Admin/fileActive.png" : "../../../Assets/Admin/fileUnactive.png")} className={props.selectedMenu === "files" ? css.iconActive : css.icon} />
                            {props.isWrap ? <label className={css.nameMenu} style={{color:props.selectedMenu === "files" ? "white" : ""}}>Файл</label> : null}
                        </div>
                    </div>
                    <ul className={`${css.list} ${props.isWrap && props.selectedMenu === "files" ? css.active : null}`}>
                        <li className={css.listItem} onClick={()=>{props.setContainer("allFiles")}} style={{color:props.container === "allFiles" ? "white" : ""}}>Бүгд</li>
                        <li className={css.listItem} onClick={()=>{props.setContainer("addFiles")}} style={{color:props.container === "addFiles" ? "white" : ""}}>Нэмэх</li>
                    </ul>
                </div>

                {/* CATEGORY */}
                <div style={{width:"100%"}}>
                    <div className={css.menuContainer} onClick={() => {setContainerAndSelected("category" , "allCategory")}}>
                        <div className={css.selectMenuContainer}>
                            <div className={`${css.activeEffect} ${props.selectedMenu === "category" ? css.active : ""}`}></div>
                            <img src={require(props.selectedMenu === "category" ? "../../../Assets/Admin/categoryActive.png" : "../../../Assets/Admin/categoryUnactive.png")} className={props.selectedMenu === "category" ? css.iconActive : css.icon} />
                            {props.isWrap ? <label className={css.nameMenu} style={{color:props.selectedMenu === "category" ? "white" : ""}}>Ангилал</label> : null}
                        </div>
                    </div>
                    <ul className={`${css.list} ${props.isWrap && props.selectedMenu === "category" ? css.active : null}`}>
                        <li className={css.listItem} onClick={()=>{props.setContainer("allCategory")}} style={{color:props.container === "allCategory" ? "white" : ""}}>Бүгд</li>
                        <li className={css.listItem} onClick={()=>{props.setContainer("addCategory")}} style={{color:props.container === "addCategory" ? "white" : ""}}>Нэмэх</li>
                    </ul>
                </div>

                {/* CUSTOMERS */}
                <div style={{width:"100%"}}>
                <div className={css.menuContainer} onClick={()=>{setContainerAndSelected("customers", "allCustomers")}} >
                    <div className={css.selectMenuContainer} >
                        <div className={`${css.activeEffect} ${props.selectedMenu === "customers" ? css.active : ""}`}></div>
                        <img src={require(props.selectedMenu === "customers" ? "../../../Assets/Admin/customersActive.png" : "../../../Assets/Admin/customersUnactive.png")} className={props.selectedMenu === "customers" ? css.iconActive : css.icon} />
                        {props.isWrap ? <label className={css.nameMenu} style={{color:props.selectedMenu === "customers" ? "white" : ""}}>Хэрэглэгчид</label> : null}
                    </div>
                    
                  

                </div>
              
                    <ul className={`${css.list} ${props.isWrap && props.selectedMenu === "customers" ? css.active : null}`}>
                        <li className={css.listItem} onClick={()=>{props.setContainer("allCustomers")}} style={{color:props.container === "allCustomers" ? "white" : ""}}>Бүгд</li>
                        <li className={css.listItem} onClick={()=>{props.setContainer("addCustomers")}} style={{color:props.container === "addCustomers" ? "white" : ""}}>Нэмэх</li>
                    </ul>
              
                </div>


               
                
                
            </div>
        </div>
    )
}   

export default ADMIN_SIDEBAR