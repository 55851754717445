import React, { useState } from "react";
import css from "./style.module.css"
import NON_TOUCHABLE_STAR from "../../STAR/NON_TOUCHABLE";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/authContext";
import axios from "axios";

const PRODUCT_CARD = (props) => {

    const {user} = useAuth()

    if (props.loading) {
        return (
          <div className={css.containerFluid}>
            <div className={css.container}>
              <div className={css.skeletonLoader}>
                <div className={css.image}></div>
    
                <div className={css.detailContainer}>
                  <div className={css.detailContainerSection}>
                    <p className={css.itemTitle}><label style={{opacity:0}}>{props.title}</label></p> 
                    <div className={css.descContainer}>
                      <p className={css.desc}><label style={{opacity:0}}>{props.desc}</label></p> 
                    </div>
                    <div className={css.buttonContainer}>
                      <button className={css.buyBtn}></button> 
                      <button className={css.buyBtn}></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    
    const handleReview = async () => {
      try 
      { 
        const response = await axios.post("https://omn1group.com/api/v1/product/review", {
          product: props.id ? props.id : null ,user: user ? user.id : null
        })
        console.log(response)
      }
      catch(err)
      {
        console.log(err)
      }
    }

    return (
      <Link to={`/files/${props.category ? props.category : null}/${props.id ? props.id : null}`} style={{marginLeft:"20px", backgroundColor:"orange", borderRadius:"5px", textDecoration:"none"}} onClick={handleReview}>
       <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.imageContainer}>
                    <img src={`https://omn1group.com/${props.image}`}  className={css.image}/>
                </div >
                <div className={css.detailContainer}>
                    <div className={css.detailContainerSection}>
                        <div className={css.detailContainerNav}>
                            <p className={css.itemTitle}>{props.title}</p>
                        </div>
                        <NON_TOUCHABLE_STAR star={props.rating ? props.rating : null} review ={props.review ? props.review : "0"}/>
                        <div className={css.descContainer}>
                            <p className={css.desc}>{props.desc}</p>
                        </div>
                        <div className={css.buttonContainer}>
                            <button className={css.buyBtn} onClick={props.handleCheckout}>
                                {props.price}₮
                            </button>

                            <button className={css.buyBtn} style={{backgroundColor:"orange", borderRadius:"10px"}}>
                                Дэлгэрэнгүй үзэх
                            </button> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Link>
       
    )
}

export default PRODUCT_CARD