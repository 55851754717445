import React from "react";
import css from "./style.module.css"

const ADD_CATEGORY =  (props) => {
    return (
        <div className={css.containerFluid}>
           ADD_CATEGORY
        </div>
    )
}

export default ADD_CATEGORY