import React from "react";
import css from "./style.module.css"

const ADD_FILE = (props) => {
    return (
        <div className={css.containerFluid}>    
            <div className={css.leftContainer}>

            </div>
            <div className={css.rightContainer}>
                <div className={css.imageContainer}>
                    <div className={css.nav}>
                        <p className={css.titleNav}>Файлын зураг оруулах</p>
                    </div>
                    <div className={css.imageContainerBody}>
                        <div className={css.inputContainer}>

                        </div>
                    </div>
                </div>
                <div className={css.productInformation}>
                    <div className={css.nav}>
                        <p className={css.titleNav}>Файлын мэдээлэл</p>
                    </div>
                    <div className={css.productInformationBody}>
                        <div className={css.productInputContainer}>
                            <div className={css.productInput}>
                                <label className={css.inputName}>Нэр</label>
                                <input type="text" className={css.input} placeholder="Файлын нэр"/>
                            </div>
                            <div className={css.productInput}>
                                <label className={css.inputName}>Ангилал</label>
                                <select className={css.input}>
                                    <option>Тоглоом</option>
                                </select>
                            </div>
                        </div>
                        <div className={css.productInputContainer}>
                            <div className={css.productInput}>
                                <label className={css.inputName}>Үнэлгээ MNT</label>
                                <input type="text" className={css.input} placeholder="Файлын зарагдах үнэлгээ"/>
                            </div>
                            <div className={css.productInput}>
                                <label className={css.inputName}>Линк</label>
                                <input type="text" className={css.input} placeholder="Файлын линк"/>

                            </div>
                        </div>

                        <div style={{marginTop:"20px"}}>
                        <label className={css.inputName}>Богино тайлбар</label>
                        <textarea className={css.shortDesc} placeholder="Файлын богино тайлбар"/>
                        </div>

                        <div style={{marginTop:"20px"}}>
                        <label className={css.inputName}>Тайлбар</label>
                        <textarea className={css.desc} placeholder="Файлын эх тайлбар"/>
                        </div>
                    </div>
                </div>
                <div className={css.footerRightContainer}>
                    <button className={css.addProductBtn}>Файл нэмэх</button>
                    <button className={css.clearBtn}>Цуцлах</button>
                </div>
            </div>    
        </div>
    )
}

export default ADD_FILE