import React, {useState, useEffect} from 'react';
import NAVBAR from '../../Source/NAVBAR';
import './App.css';
import LOGIN from '../../Source/LOGIN';
import BURGER_MENU from '../../Source/BURGER_MENU';
import SHADOW from '../../Components/MAIN_APP/SHADOW';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home';
import FOOTER from '../../Source/FOOTER';
import SUBSCRIPTION from '../SUBSCRIPTION';
import FAVOURITES from '../FAVOURITES';
import CONTENT from '../CONTENT';
import PROFILE from '../Profile';
import PRODUCT_DETAIL from '../PRODUCT_DETAIL';
import MESSAGE_MODAL from '../../Source/MESSAGE_MODAL';
import { useMessage } from '../../Context/messageContext';
import CATEGORY_PAGE from '../CATEGORY_PAGE';
import FILES_ADD from '../FILES_ADD';
import CHECKOUT_PAGE from '../CHECKOUT_PAGE';
import { useAuth } from '../../Context/authContext';
import ADMIN from '../ADMIN';

function App() {
  const [modal, setModal] = useState(false)
  const [burgerMenu, setBurgerMenu] = useState(false)
  const [modalType, setModalType] = useState("login")

  const {modalMessage} = useMessage()

  const {userProduct, user} = useAuth()

  const handleResize = () => {
    if (window.innerWidth > 767) {
      setBurgerMenu(false); 
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  console.log(userProduct)
  return (
    <div className="App">
        {
          user.role === "user" || user.role === ""
          ?
          <><MESSAGE_MODAL/>
          <NAVBAR setModal={setModal} setBurgerMenu={setBurgerMenu}/>
          <LOGIN setModal={setModal} modal={modal} type={modalType} setType={setModalType}/>
          {
            burgerMenu || modalMessage ?  <SHADOW onclick={setBurgerMenu}/>
            :null
          }
          <BURGER_MENU menu={burgerMenu} setBurgerMenu={setBurgerMenu} setLoginType={setModalType} setModal={setModal}/>
  
          <Routes >
            <Route path='/' element={<Home setModalType={setModalType} setModal={setModal}/>}/>
            <Route path='/subscription' element={<SUBSCRIPTION/>}/>
            <Route path='/favourites' element={<FAVOURITES/>}/>
            <Route path='/content' element={<CONTENT/>}/>
            <Route path='/profile' element={<PROFILE/>}/>
            <Route path='/files/:category' element={<CATEGORY_PAGE/>}/>
            <Route path='/files/:category/:productId' element={<PRODUCT_DETAIL/>}/>
            <Route path='/files/add/product' element={<FILES_ADD/>}/>
            <Route path='/files/:category/:productId/:checkout' element={<CHECKOUT_PAGE/>}/>
          </Routes>
          <FOOTER/></>

        : 

        user.role === "admin"
        ?
        <ADMIN/>
        : null
        }
    </div>
  );
}

export default App;
