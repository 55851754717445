import React, { useState } from "react";
import css from "./style.module.css"
import user from "../../../Assets/user.png"
import emailIcon from "../../../Assets/email.png"
import passwordIcon from "../../../Assets/password.png"
import exit from "../../../Assets/exit.png"
import axios from "axios";
import LOADING from "../../LOADING";
import { useMessage } from "../../../Context/messageContext";

const CONTAINER_REGISTER = (props) => {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isLoading , setIsLoading] = useState(false)

    const [errorMessage , setErrorMessage] = useState("")

    const {setModalMessage} = useMessage()


    const handleRegister = async () => {
        try 
        {
            const response = await axios.post("https://omn1group.com/api/v1/auth/v1/register", {username, email , password , confirmPassword})
            if(response.status === 200)
            {
                setIsLoading(true)
                props.setModal(false)
                setTimeout(() => {
                    setIsLoading(false)
                    setModalMessage("Хэрэглэгчийн бүртгэл амжилттай үүслээ.")
                    
                },2000)
            }
        }
        catch(err)
        {
            setErrorMessage(err.response.data.message)
        }
    } 

        if(isLoading) return <LOADING/>

    return (
        <div className={css.containerFluid}>

            <div className={css.exitContainer}>
                <img src={exit} className={css.exit} onClick={()=>{props.setModal(false)}}/>    
            </div>
            <p className={css.title}>БҮРТГҮҮЛЭХ</p>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={user} className={css.icon}/>
                </div>
                <input type="text" placeholder="Нэвтрэх нэр" className={css.input} onChange={(e) => {setUsername(e.target.value)
                                                                                                     setErrorMessage("")
                }}/> 
                
            </div>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={emailIcon} className={css.icon}/>
                </div>
                <input type="text" placeholder="И-Мейл хаяг" className={css.input} onChange={(e)=>{setEmail(e.target.value)
                                                                                                    setErrorMessage("")
                }}/> 
                
            </div>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={passwordIcon} className={css.icon}/>
                </div>
                <input type="text" placeholder="Нууц үг" className={css.input} onChange={(e)=> {setPassword(e.target.value)
                                                                                                setErrorMessage("")
                }}/> 
                
            </div>
            <div className={css.inputContainer}>
                <div className={css.inputIcon}>
                    <img src={passwordIcon} className={css.icon}/>
                </div>
                <input type="text" placeholder="Нууц үг давтах" className={css.input} onChange={(e)=>{setConfirmPassword(e.target.value)
                                                                                                      setErrorMessage("")   
                }}/> 
                
            </div>
                <p className={css.errorMessage}>{errorMessage}</p>
            <button className={css.buttonRegister} onClick={handleRegister}>Бүртгүүлэх</button>
            <p className={css.bottomTxt}>Бүртгэлтэй юу тэгвэл <label className={css.linkLogin} onClick={()=>{props.setIsLogin("login")}}>энд</label> дарна уу</p>

        </div>
    )
}

export default CONTAINER_REGISTER