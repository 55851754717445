import React, { useEffect, useState } from "react";
import css from "./style.module.css"
import axios from "axios";
import { useCategory } from "../../../Context/categoryContext";

const ALL_FILE = (props) => {
    const [products, setProducts] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [maxPage, setMaxPage] = useState(null)
    const item = 10;


    const {categories} = useCategory()

    const [category ,setCategory] = useState("")
    const [rating, setRating] = useState("")
    const [order, setOrder] = useState("")


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const queryParams = new URLSearchParams({
                    page: activePage,
                    item: 10,
                });
    
                if (category) {
                    queryParams.append('category', category);
                }
                if (rating) {
                    queryParams.append('rating', rating);
                }
                if(order)
                {
                    queryParams.append('order', order)
                }
    
                const response = await axios.get(`https://omn1group.com/api/v1/admin/products?${queryParams.toString()}`);
                console.log(response)
                if (response) {
                    setProducts(response.data.data)
                    setMaxPage(response.data.maxPage)
                }
            } catch (err) {
                console.log(err)
                if(err.response.status === 404)
                {
                    setProducts([])
                }
                
                
            }
        };
    
        fetchProducts();
    }, [activePage, category, rating, order]);

    const generatePageNumbers = (maxPage, activePage) => {
        const range = 5; 
        let start = Math.max(1, activePage - Math.floor(range / 2));
        let end = Math.min(maxPage, start + range - 1);

        if (end - start < range - 1) {
            start = Math.max(1, end - range + 1);
        }

        const pageNumbers = [];
        for (let i = start; i <= end; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const pageNumbers = generatePageNumbers(maxPage, activePage);

    

    return (
        <div className={css.containerFluid}>
            <div className={css.sortContainer}>

                <div className={css.sortContainerBody}>
                    <div className={css.containerInput}>
                        <label className={css.inputTxt}>Ангилал</label>
                        <select className={css.select} onChange={(e)=>{setCategory(e.target.value)}} value={category || ""}>
                            <option value={""}>Сонгох</option>
                            {
                                categories && categories.length > 0
                                ?
                                categories.map((e, i) => (
                                    <option key={i} value={e.name}>{e.name}</option>
                                ))
                                : 
                                null
                            }
                        </select>
                    </div>
                    <div className={css.containerInput}>
                        <label className={css.inputTxt}>Үнэлгээ</label>
                        <select className={css.select} onChange={(e)=>{setRating(e.target.value)}}>
                            <option value={""}>Сонгох</option>
                            <option value={"1.0000"}>1</option>
                            <option value={"2.0000"}>2</option>
                            <option value={"3.0000"}>3</option>
                            <option value={"4.0000"}>4</option>
                            <option value={"5.0000"}>5</option>
                        </select>
                    </div>
                    <div className={css.containerInput}>
                        <label className={css.inputTxt}>Шүүх</label>
                        <select className={css.select} onChange={(e)=>{setOrder(e.target.value)}}>
                            <option value={""}>Сонгох</option>
                            <option value={"ASC"}>Үнэ өсөхөөр</option>
                            <option value={"DESC"}>Үнэ багасхаар</option>
                            <option value={"DESCPRICE"}>Хамгийн их зарагдсан</option>
                            <option value={"ASCPRICE"}>Хамгийн бага зарагдсан</option>
                        </select>
                    </div>
                    <div className={css.containerInput}>
                        <label className={css.inputTxt}>Байршуулсан</label>
                        <select className={css.select}>
                            <option>Сонгох</option>
                            <option>Их</option>
                            <option>Бага</option>
                            
                        </select>
                    </div>
                </div>
            </div>
           <div className={css.container}>
                <div className={css.nav}>
                    <p className={css.titleNav}>Бүх файл</p>
                    <button className={css.addBtn}>Файл нэмэх</button>
                </div>
                <div className={css.containerBody}>
                    <div className={css.containerBodyNav}>
                        <p className={css.containerBodyNavItem}>Эзэмшигч / нэр</p>
                        <p className={css.containerBodyNavItem}>Үнэ</p>
                        <p className={css.containerBodyNavItem}>Зарагдсан</p>
                        <p className={css.containerBodyNavItem}>Ангилал</p>
                        <p className={css.containerBodyNavItem}>Үнэлгээ</p>
                        <p className={css.containerBodyNavItem}>Үйлдэл</p>
                    </div>
                    <div className={css.containerBodyListContainer}>
                        {
                            products && products.length > 0
                            ?
                            products.map((e, i) => (
                                <div className={css.mapListItem}>
                                <div className={css.containerImageAndName}>
                                    <img className={css.itemImage} src={`http://localhost:8000/${e.img_url}`}/>
                                    <div className={css.detailContainer}>
                                        <p className={css.name}>{e.product_name ? e.product_name : "Хоосон"}</p>
                                        <p className={css.username}>{e.username ? e.username : "Хоосон"}</p>
                                    </div>
                                </div>
                                <p className={css.price}>{e.price ? e.price : "Хоосон"} MNT</p>
                                <p className={css.selled}>{e.total_sold ? e.total_sold : "0"}</p>
                                <p className={css.category}>{e.name ? e.name : "Хоосон"}</p>
                                <div className={css.ratingContainer}>
                                    <img src={require("../../../Assets/Admin/star.png")} className={css.star}/>
                                    <p className={css.rating}>{e.rating ? e.rating : "0"}</p>
                                    <p className={css.reviews}>{e.reviews ? e.reviews : "0"} үзэгч</p>
                                </div>
                                <div className={css.uildelContainer}>
                                    <div className={css.uildelBlock}>
                                        <img src={require("../../../Assets/viewAdmin.png")} style={{width:"14px", height:"16px"}}/>
                                    </div>
                                    <div className={css.uildelBlock}>
                                        <img src={require("../../../Assets/pencil.png")} style={{width:"14px", height:"14px"}}/>
                                    </div>
                                    <div className={css.uildelBlock}>
                                        <img src={require("../../../Assets/trash.png")} style={{width:"14px", height:"14px"}}/>
                                    </div>
                                </div>
                                </div>
                            ))
                            :
                            null
                        }
                    </div>
                    <div className={css.paginationContainer}>
                        <div className={css.pagination}>
                            <button className={css.btnPage} onClick={()=>{setActivePage(activePage -1 )}} disabled={activePage === 1}>Өмнөх</button>
                
                            {
                                pageNumbers ? 
                                pageNumbers.map((e, i) => (
                                    <button className={activePage === e ? css.pageBtnActive : css.pageBtn} onClick={()=>{setActivePage(e)}}>{e}</button>
                                ))
                                : 
                                null
                            }
                            <button className={css.btnPage} onClick={()=>{setActivePage(activePage  + 1 )}} disabled={activePage === maxPage}>Дараах</button>
                        </div>
                    </div>
                </div>  
           </div>
        </div>
    )
}

export default ALL_FILE 