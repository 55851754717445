import React, { act, useEffect, useState } from "react";
import css from "./style.module.css"
import { Bar , Doughnut} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
  
} from "chart.js";
import axios from "axios";



ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler, ArcElement);

const DASHBOARD = (props) => {

    const progress = 85;
    const data = {
        labels: ["Progress"], 
        datasets: [
          {
            data: [progress, 100 - progress], 
            backgroundColor: ["#ff6c2f", "#e0e0e0"], 
            borderWidth: 0, 
            cutout: "80%", 
            rotation: 230, 
            circumference: 260, 
          },
        ],
      };
    
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false, // Hide legend
          },
          tooltip: {
            enabled: false, // Disable tooltips
          },
        },
      };

    const [invoices, setInvoices] = useState([])
    const [payedInvoices, setPayedInvoices] = useState([])
    const [withdraw, setWithDraw] = useState()
    

    const [users, setUsers] = useState([])

    useEffect(() => {
        const fetchDatas = async () => {
            try 
            {
                const response = await axios.get("https://omn1group.com/api/v1/admin/dashboard")
                if(response)
                {
                    setInvoices(response.data.invoice)
                    const filterPayed = response.data.invoice.filter((item) => item.payment === 1)
                    setPayedInvoices(filterPayed)
                    const total = response.data.invoice.reduce((sum, payment) => {
                        return sum + payment.withdraw;
                      }, 0);
                    setWithDraw(parseFloat(total))
                    setUsers(response.data.user)
                }

            }
            catch(err)
            {
                console.log(err)
            }
        }
        fetchDatas()
    }, [])


    const [activePage, setActivePage] = useState(1);
    const [recentInvoice, setRecentInvoice] = useState([]);
    const [totalInvoice, setTotalInvoice] = useState(0);
    const [maxPage, setMaxPage] = useState(1);
    const itemsPerPage = 5; 

    useEffect(() => {
        const fetchInvoices = async () => {
            try {
                const response = await axios.get(`https://omn1group.com/api/v1/admin/recent?page=${activePage}&itemsPerPage=${itemsPerPage}`);
                if (response) {
                    console.log(response);
                    setRecentInvoice(response.data.data);
                    setMaxPage(response.data.maxPage);
                    setTotalInvoice(response.data.totalCount);
                }
            } catch (err) {
                console.error("Error fetching invoices:", err);
            }
        };
        fetchInvoices();
    }, [activePage]);

    const generatePageNumbers = (maxPage, activePage) => {
        const range = 5; // Number of pages to display in the range
        let start = Math.max(1, activePage - Math.floor(range / 2));
        let end = Math.min(maxPage, start + range - 1);

        if (end - start < range - 1) {
            start = Math.max(1, end - range + 1);
        }

        const pageNumbers = [];
        for (let i = start; i <= end; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const pageNumbers = generatePageNumbers(maxPage, activePage);

    
    const getMonthlyTotal = (month) => {
        const filteredInvoices = invoices.filter(
          (item) => item.payment === 1 && item.date.split("T")[0].startsWith(`2024-${month}`)
        );
        
        return filteredInvoices.length;
        };



    return (
        <div className={css.containerFluid}>
            <div className={css.topSection}>
                <div className={css.leftTopSection}>
                    <div className={css.leftTopSectionNav}>
                        <p className={css.leftTopSectionNavTxt}>Нийт статистик мэдээлэл</p>
                    </div>
                    <div className={css.leftTopSectionBody}>
                        {/* INVOICE */}
                        <div className={css.statBlock}>
                            <div className={css.statBlockTop}>
                                <div className={css.statIconContainer}>
                                    <img src={require("../../../Assets/Admin/dollarSign.png")}/>
                                </div>
                                <div className={css.statNumName}>
                                    <p className={css.nameStat}>Орлого</p>
                                    <p className={css.num}>{withdraw ? withdraw : "0"}</p>
                                </div>
                            </div>
                            <div className={css.statBlockBot}>
                                    <p className={css.stonk}><img src={require("../../../Assets/Admin/stonkAdmin.png")} style={{height:"20px", marginTop:"2px"}}/>2.3%</p>
                                    <p className={css.viewMore}>Өссөн</p>
                            </div>
                        </div>
                        {/* CHECKED INVOICE  */}
                        <div className={css.statBlock}>
                            <div className={css.statBlockTop}>
                                <div className={css.statIconContainer}>
                                <img src={require("../../../Assets/Admin/paymentCheck.png")}/>

                                </div>
                                <div className={css.statNumName}>
                                    <p className={css.nameStat}>Төлөгдсөн</p>
                                    <p className={css.num}>{payedInvoices && payedInvoices.length > 0 ? payedInvoices.length : "0"}</p>
                                </div>
                            </div>
                            <div className={css.statBlockBot}>
                                <p className={css.stonk}><img src={require("../../../Assets/Admin/stonkAdmin.png")} style={{height:"20px", marginTop:"2px"}}/>2.3%</p>
                                <p className={css.viewMore}>Өссөн</p>
                            </div>
                        </div>
                        {/* UPLOAD */}
                        <div className={css.statBlock}>
                            <div className={css.statBlockTop}>
                                <div className={css.statIconContainer}>
                                    <img src={require("../../../Assets/Admin/uploadAdmin.png")}/>
                                </div>
                                <div className={css.statNumName}>
                                    <p className={css.nameStat}>Файл</p>
                                    <p className={css.num}>123</p>
                                </div>
                            </div>
                            <div className={css.statBlockBot}>
                                <p className={css.stonkRed}><img src={require("../../../Assets/Admin/stonkDown.png")} style={{height:"20px", marginTop:"0px"}}/>0.3%</p>
                            <p className={css.viewMore}>Буурсан</p>
                            </div>
                        </div>
                        {/* USER */}
                        <div className={css.statBlock}>
                            <div className={css.statBlockTop}>
                                <div className={css.statIconContainer}>
                                    <img src={require("../../../Assets/Admin/userAdmin.png")}/>
                                </div>
                                <div className={css.statNumName}>
                                    <p className={css.nameStat}>Хэрэглэгч</p>
                                    <p className={css.num}>{users && users.length > 0 ? users.length : "0"}</p>
                                </div>
                            </div>
                            <div className={css.statBlockBot}>
                                <p className={css.stonkRed}><img src={require("../../../Assets/Admin/stonkDown.png")} style={{height:"20px", marginTop:"0px"}}/>1.3%</p>
                                <p className={css.viewMore}>Буурсан</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css.rightTopSection}>
                  <div className={css.rightTopSectionResponsive}>
                  <div className={css.rightTopSectionNav}>
                        <p className={css.rightTopSectionNavTxt}>Үзүүлэлт</p>
                    </div>
                    <div className={css.rightTopSectionBody}>
                    <Bar
                        data={{
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct" , "Nov", "Dec"],
                        datasets: [
                            {
                            label: "Худалдан авалт",
                            data: [getMonthlyTotal("01"),getMonthlyTotal("02"),getMonthlyTotal("03"),getMonthlyTotal("04"),getMonthlyTotal("05"),getMonthlyTotal("06"),getMonthlyTotal("07"),getMonthlyTotal("08"),getMonthlyTotal("09"),getMonthlyTotal("10"),getMonthlyTotal("11"),getMonthlyTotal("12")],
                            backgroundColor: "#ff6c2f",
                            borderColor: "#ff6c2f",
                            borderWidth: 1,
                            barThickness:15,
                            borderRadius:2
                            },
                        ],
                        }}
                        options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                            display:false,
                            },
                            title: {
                            display: true,
                            },
                    
                        },
                        scales: {
                            x: {
                              grid: {
                                drawBorder: false, // Hides the x-axis border
                                display: false,    // Optionally, hide grid lines on x-axis
                              },
                            },
                            y: {
                              grid: {
                                drawBorder: true,  // Keeps the y-axis border visible
                              },
                              beginAtZero: true,
                            },
                          
                        }
                        }}
                    />
                    </div>
                    <div className={css.rightTopSectionFooter}>
                        <div className={css.rightTopSectionFooterItem}>
                            <div className={css.radius}></div>
                            <p className={css.footerItem}>Худалдан авалт</p>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            
            
            <div className={css.middleSection}>
                <div className={css.middleSectionLeft}>
                    <div className={css.middleSectionItemsNav}>
                        <p className={css.middleSectionItemsNavTxt}>Ашиглалт</p>
                    </div>
                    <div className={css.middleSectionLeftBody}>
                        <div className={css.middleSectionLeftBodyTop}>
                            <div className={css.middleSectionLeftBodyTopChart}>
                            <Doughnut data={data} options={options} style={{backgroundColor:"white"}}/>
                            </div>
                            <div className={css.middleSectionLeftBodyTopChartFooter}>
                                <p className={css.progress}>{progress}%</p>
                                <p className={css.progressName}>Тогтмол Хэрэглэгч</p>

                            </div>
                        </div>
                        <div className={css.middleSectionLeftBodyMiddle}>
                                <div className={css.leftMiddleSection}>
                                    <p className={css.middleSectionLeftBodyItemTop}>Өнгөрсөн сар</p>
                                    <p className={css.middleSectionLeftBodyItemBot}>23k</p>
                                </div>
                                <div className={css.rightMiddleSection}>
                                    <p className={css.middleSectionLeftBodyItemTop}>Энэ сар</p>
                                    <p className={css.middleSectionLeftBodyItemBot}>34k</p>
                                </div>
                        </div>
                        <div className={css.middleSectionLeftBodyFooter}>
                            <div className={css.middleSectionLeftBodyFooterItem}>
                                <p className={css.middleSectionLeftBodyFooterItemTxt}>Илүү үзэх</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={css.middleSectionMiddle}>
                    <div className={css.middleSectionMiddleNav}>
                        <p className={css.middleSectionMiddleNavTxt}>Файл</p>
                    </div>
                    <div className={css.middleSectionMiddleBody}>
                        <div className={css.middleSectionMiddleBodyNav}>
                            <p className={css.middleSectionMiddleBodyNavTxt}>Хэрэглэгч</p>
                            <p className={css.middleSectionMiddleBodyNavTxt}>Файл</p>
                            <p className={css.middleSectionMiddleBodyNavTxt}>Зарагдсан</p>
                        </div>  
                        <div className={css.middleSectionMiddleBodyItemsContainer}>
                            {
                                users && users.length > 0 
                                ?
                                users.map((e, i) => (
                                <div className={css.mapListItem}>
                                    <p className={css.userName}>{e.username ? e.username : null}</p>
                                    <p className={css.files}>{e.total_products ? e.total_products : null}</p>
                                    <p className={css.selled}>105</p>
                                </div>
                                ))
                                :
                                null
                            }
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>34</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>34</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>34</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>34</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>34</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>34</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>34</p>
                                <p className={css.selled}>105</p>
                            </div>
                            

                        </div>
                    </div>
                </div>


                <div className={css.middleSectionRight}>
                    <div className={css.middleSectionMiddleNav}>
                            <p className={css.middleSectionMiddleNavTxt}>Файл</p>
                    </div>
                    <div className={css.middleSectionMiddleBody}>
                        <div className={css.middleSectionMiddleBodyNav}>
                            <p className={css.middleSectionMiddleBodyNavTxt}>Нэр</p>
                            <p className={css.middleSectionMiddleBodyNavTxt}>Файл</p>
                            <p className={css.middleSectionMiddleBodyNavTxt}>Зарагдсан</p>
                        </div>  
                        <div className={css.middleSectionMiddleBodyItemsContainer}>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>God Of war</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>God Of war</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>God Of war</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>God Of war</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>God Of war</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>God Of war</p>
                                <p className={css.selled}>105</p>
                            </div>
                            <div className={css.mapListItem}>
                                <p className={css.userName}>Бямба</p>
                                <p className={css.files}>34</p>
                                <p className={css.selled}>105</p>
                            </div>
                            

                        </div>
                    </div>
                </div>
                    
            </div>

            <div className={css.footerSection}>
                <div className={css.footerNav}>
                    <p className={css.footerNavTxt}>Худалдан авалтууд</p>
                </div>
                <div className={css.footerBody}>
                    <div className={css.footerBodyNav}>
                        <p className={css.footerBodyNavItem}>ID</p>
                        <p className={css.footerBodyNavItem}>Хэрэглэгч</p>
                        <p className={css.footerBodyNavItem}>Файл</p>
                        <p className={css.footerBodyNavItem}>Төлбөр</p>
                        <p className={css.footerBodyNavItem}>Орлого</p>
                        <p className={css.footerBodyNavItem}>Шилжүүлсэн</p>
                        <p className={css.footerBodyNavItem}>Эзэмшигч</p>
                        <p className={css.footerBodyNavItem}>Он сар өдөр</p>

                    </div>
                    <div className={css.footerBodyContainer}>
                        {
                            recentInvoice && recentInvoice.length > 0
                            ?
                                recentInvoice.map((e,i) => (
                                    <div className={css.invoiceMapItem}>
                                        <p className={css.invoiceMapItemTxt }>{e.id ? e.id : "хоосон"}</p>
                                        <p className={css.invoiceMapItemTxt }>{e.checkout_user ? e.checkout_user: "хоосон"}</p>
                                        <p className={css.invoiceMapItemTxt }>{e.checkout_product_name ? e.checkout_product_name : "хоосон"}</p>
                                        <p className={css.invoiceMapItemTxt }>{e.price ? e.price : "хоосон"} MNT</p>
                                        <p className={css.invoiceMapItemTxt }>{e.withdraw ? e.withdraw : "хоосон"} MNT</p>
                                        <p className={css.invoiceMapItemTxt }>{e.deposit ? e.deposit : "хоосон"}</p>
                                        <p className={css.invoiceMapItemTxt }>{e.product_user ? e.product_user : "хоосон"}</p>
                                        <p className={css.invoiceMapItemTxt }>{e.date ? e.date.split("T")[0] : "хоосон"}</p>
                                    </div>
                                ))
                            :   
                            null
                        }
                       
                    </div>
                    <div className={css.paginationContainer}>
                        <p className={css.paginationName}>
                            <label style={{fontWeight:"600",paddingRight:"5px"}}>
                                {totalInvoice ? totalInvoice : "0"} нь худалдан авалт байна.
                            </label> 
                              
                            
                        </p>

                        <div className={css.pagination}>
                            <button className={css.paginationBtn}     disabled={activePage === 1}  onClick={()=>{setActivePage(activePage - 1)}}>{"<"}</button>
                            {
                                pageNumbers
                                ? 
                                pageNumbers.map((e, i) => (
                                    <div className={e === activePage ? css.paginationPageActive : css.paginationPage} onClick={()=>{setActivePage(e)}}>{e}</div>
                                ))
                                :
                                null
                            }
                            <button className={css.paginationBtnRight}     disabled={activePage === maxPage}  onClick={()=>{setActivePage(activePage + 1)}}>{">"}</button >
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className={css.bottomItem}>
                <p className={css.bottomItemTxt}>2024 ©</p>
            </div>
        </div>
    )
}

export default DASHBOARD