import React, { useState } from "react";
import css from "./style.module.css"
import USER_CONTENT from "../../Components/CONTENT/1. USER_CONTENT";
import { Link } from "react-router-dom";

const CONTENT = (props) => {
    const [container, setContainer] = useState("userContent")

    return (
        <div className={css.containerFluid}>
            <div className={css.container}>
                <div className={css.sideBar}>
                    <p className={css.title}>
                        Контент
                    </p>
                    <div className={css.hr}></div>

                    <ul className={css.list}>
                        <li className={css.listItem} style={{backgroundColor:"#383838"}}>Таны Контент</li>
                        <Link to={"/files/add/product"} style={{textDecoration:"none"}}>
                        <li className={css.listItem}>Файл байршуулах</li>
                        </Link>

                    </ul>
                    
                </div>
                <div className={css.containerSection}>
                    <div className={css.containerMenu}>
                        {
                            container === "userContent" 

                            ?

                            <USER_CONTENT/>

                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CONTENT